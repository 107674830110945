// Если сборка в режиме разработки, то false
const productionFlag = true;

const config = productionFlag
    ? {
        siteUrl: 'https://prorab.domovedov.com',
        apiUrl: 'https://prorab.domovedov.com',
    }
    : {
        siteUrl: 'https://dev.domovedov.com',
        apiUrl: 'https://dev.domovedov.com',
    };

export default config;
